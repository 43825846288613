import { Content } from '@/types/content';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppState } from './useAppState';

export const useInfinityScrollArticle = ({
  intersectionTargetRef,
}: {
  intersectionTargetRef: RefObject<HTMLDivElement>;
}) => {
  const [{ device, scrollArticles }, appStateDispatcher] = useAppState();
  const [loadedArticles, setLoadedArticles] = useState<Content[]>([]);
  const [isLoading, setLoading] = useState(false);

  const loadNextArticle = useCallback(async () => {
    const url = getUrl('/api/page');
    const articleId = scrollArticles[loadedArticles.length]?.article_id;
    if (!url || !articleId || isLoading) return null;

    setLoading(true);

    const params = new URLSearchParams({ route: `article/${articleId}`, device });
    url.search = params.toString();
    const response = await http.get<Content>(url.href);
    const data = response?.data;

    if (data) {
      data.meta.scrollArticleNumber = scrollArticles.findIndex((a) => a.article_id === articleId) + 1;
      setLoadedArticles((prevLoadedArticles) => [...prevLoadedArticles, data]);
    }

    setLoading(false);

    return data;
  }, [scrollArticles, loadedArticles, device, isLoading]);

  useEffect(() => {
    if (!scrollArticles.length || scrollArticles.length === loadedArticles.length || !intersectionTargetRef.current) {
      appStateDispatcher({ activeInfinityScrollArticle: false });
      return;
    }
    appStateDispatcher({ activeInfinityScrollArticle: true });

    const observer = new IntersectionObserver(([entry]) => {
      if ((entry.isIntersecting || entry.boundingClientRect.y < 0) && !isLoading) {
        loadNextArticle().then((data) => {
          if (!data) {
            observer.disconnect();
            appStateDispatcher({ activeInfinityScrollArticle: false });
          }
        });
      }
    });

    observer.observe(intersectionTargetRef.current as Element);

    return () => observer.disconnect();
  }, [scrollArticles, intersectionTargetRef, isLoading, loadNextArticle, loadedArticles, appStateDispatcher]);

  return useMemo(() => ({ articles: loadedArticles, isLoading }), [loadedArticles, isLoading]);
};
