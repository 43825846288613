import { getCookiesFromString } from '@/utils/getCookiesFromString';
import { getDomain } from '@/utils/getDomain';
import { getRecommendationMicroservice } from '@/utils/getRecommendationMicroservice';
import { getSite } from '@/utils/getSite';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { isConsentDisabled } from '@/utils/isConsentDisabled';
import { isServer } from '@/utils/isServer';
import { logger } from '@/utils/logger';
import { getSeenArticleIds } from '@/utils/seenArticles';
import { waitFor } from '@/utils/waitFor';
import { AxiosError } from 'axios';
import { getSiteId } from 'lib/labrador/utils';
import { pick } from 'lodash-es';

export interface ScrollArticle {
  article_id: string;
  type: string;
  model: string;
}

export interface RecommendedArticle {
  article_id: string;
  type: string;
  model: string;
}

interface RecommendationResponse {
  scroll_articles: ScrollArticle[];
  recommended_articles: RecommendedArticle[];
}

const getCookieId = async () => {
  if (isConsentDisabled) return '';

  await waitFor(() => document.cookie.indexOf('OptanonAlertBoxClosed=') >= 0, {
    checkInterval: 100,
    timeout: 5000,
  }).catch(() => {});

  const isPersonalizationAccepted = await waitFor(() => window.OnetrustActiveGroups?.indexOf('C0004') >= 0, {
    checkInterval: 100,
    timeout: 1000,
  }).catch(() => false);

  if (!isPersonalizationAccepted) return '';

  const cookieId = getCookiesFromString(document.cookie)?.['_ga']?.split('.')?.slice(-2)?.join('.') ?? '';

  return cookieId;
};

const sendSlackNotification = (payload: Record<string, any>, key: string) => {
  const handler = 'ocelotScrollArticlesError';
  const requestInterval = 0.5 * 60 * 60 * 1000; // 30 min

  http.post(
    '/api/slackNotifier',
    {
      key,
      handler,
      payload,
      requestInterval,
    },
    { timeout: 20000 },
  );
};

export const getRecommendedArticles = async (): Promise<RecommendationResponse> => {
  if (isServer()) {
    logger.error('Fetching article recommendations is not available on server');
    return { scroll_articles: [], recommended_articles: [] };
  }

  await waitFor(() => Boolean(getSeenArticleIds()?.length), { checkInterval: 200, timeout: 2000 });

  const url = getUrl('/articleRecommendationsv2', getRecommendationMicroservice());

  if (!url) {
    logger.error('Recommendation microservice URL not found');
    return { scroll_articles: [], recommended_articles: [] };
  }

  const payload = {
    article_id: getSeenArticleIds().map(String),
    site: getSite(),
    cookie_id: await getCookieId(),
  };

  const response = await http
    .post(url.href, payload, {
      timeout: 30000,
      skipInterceptors: { response: { rejected: true } },
      'axios-retry': { retries: 1, retryCondition: () => true },
    })
    .catch((err: AxiosError) => {
      if (![AxiosError.ERR_NETWORK, AxiosError.ECONNABORTED].includes(err.code!)) {
        sendSlackNotification(
          {
            message: `
              ${getDomain()}: Personalized scroll articles request failed.
              ${JSON.stringify({
                ...pick(err, ['code', 'message', 'name', 'status']),
                payload,
              })}
          `,
          },
          `scroll-articles-failed-${getSiteId()}`,
        );
      }
      return null;
    });

  if (!response) return { scroll_articles: [], recommended_articles: [] };

  if (!response?.data?.scroll_articles?.length) {
    logger.error('Scroll articles not found!', payload);
    sendSlackNotification(
      {
        message: `
          ${getDomain()}: Personalized scroll articles request succeeded but did not return articles.
          ${JSON.stringify({ status: response.status, response: response.data, payload })}
        `,
      },
      `scroll-articles-not-found-${getSiteId()}`,
    );
  }

  return response.data;
};
